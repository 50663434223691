import { CloseOutlined, FilterAltOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { identity, pickBy } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import BoxTitle from "../../components/BoxTitle";
import Debounced from "../../components/Debounced";
import DeclarationsFilters from "../../components/DeclarationsFilters";
import { useApplicationsQueryParams } from "../../hooks/useApplicationsQueryParams";
import {
  cancelApplicationAcceptance,
  fetchAutoAssignmentSetting,
  getLastApplicationAcceptanceDate,
  storeApplicationAcceptance,
  toggleAutoAssignment,
} from "../../services/useEndpoint";
import { selectCurrentUser } from "../../store/reducers/currentUserReducer";
import { ROLES } from "../../utils/constants";
import {
  getApplications,
  getArchiveApplications,
} from "../SpecialistDeclarations/api";
import AutoConfirmModal from "./components/AutoConfirmModal";
import TableDirectorDeclarations from "./components/TableDirectorDeclarations";
import TableDirectorDeclarationsArchieve from "./components/TableDirectorDeclarationsArchieve";

const Declaration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tab, setTab] = useState<"active" | "archive">("active");
  const [year, setYear] = useState<number | null>(
    dayjs().add(-5, "years").year(),
  );

  const [
    {
      search,
      page,
      per_page,
      date,
      id,
      official_number,
      review_days_count,
      status,
      type,
      overdue,
      current_executor,
    },
    setQuery,
  ] = useApplicationsQueryParams();

  const {
    data: applicationAcceptance,
    isSuccess: applicationAcceptanceIsSuccess,
  } = useQuery({
    queryKey: ["application-acceptance"],
    queryFn: getLastApplicationAcceptanceDate,
  });

  const {
    data: applications,
    isSuccess: applicationsIsSuccess,
    isLoading: applicationsIsLoading,
  } = useQuery({
    queryKey: [
      "applications",
      search,
      page,
      per_page,
      date,
      id,
      official_number,
      review_days_count,
      status,
      overdue,
      type || applicationAcceptance?.type,
      current_executor?.id,
    ].filter(Boolean),
    queryFn: ({ signal }) =>
      getApplications({
        signal,
        params: pickBy(
          {
            search,
            page,
            per_page,
            date: date ? dayjs(date).format("YYYY-MM-DD") : null,
            id,
            official_number,
            review_days_count,
            status_id: status,
            type: type
              ? type
              : applicationAcceptance?.type === 1
                ? "main"
                : "additional",
            current_executor_id: current_executor?.id,
            overdue,
          },
          identity,
        ),
      }),
    enabled: applicationAcceptanceIsSuccess && tab === "active",
  });

  const {
    data: archiveApplications,
    isSuccess: archiveApplicationsIsSuccess,
    isLoading: archiveApplicationsIsLoading,
  } = useQuery({
    queryKey: [
      "archive-applications",
      search,
      page,
      overdue,
      per_page,
      year,
    ].filter(Boolean),
    queryFn: ({ signal }) =>
      getArchiveApplications({
        signal,
        params: pickBy({ search, page, per_page, year, overdue }, identity),
      }),
    enabled: applicationAcceptanceIsSuccess && tab === "archive",
  });

  const [expanded, setExpanded] = useState(false);

  const [isAutoAssignmentEnabled, setIsAutoAssignmentEnabled] = useState(false);
  const [isAcceptingApplications, setIsAcceptingApplications] = useState(false);
  const [
    selectedTypeAcceptingApplication,
    setSelectedTypeAcceptingApplication,
  ] = useState<number>(1);

  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const user = useSelector(selectCurrentUser);

  const handleOpenModalAcceptingApplications = () => {
    setIsConfirmationOpen(true);
  };

  const handleStartAcceptingApplications = async () => {
    try {
      const dataId = await storeApplicationAcceptance(
        selectedTypeAcceptingApplication,
        fromDate,
        toDate,
      );
      setSelectedId(dataId.id);
      localStorage.setItem("isAcceptingApplications", JSON.stringify(true));
      setIsAcceptingApplications(true);
      setIsConfirmationOpen(false);
    } catch (error) {
      console.error("Ошибка при запуске приема заявок:", error);
    }
  };

  const handleCancelAcceptingApplications = async () => {
    const id = selectedId ?? applicationAcceptance?.id;
    if (!id) return;

    await cancelApplicationAcceptance(id);
    setIsConfirmationOpen(false);
    setIsAcceptingApplications(false);
    localStorage.setItem("isAcceptingApplications", JSON.stringify(false));
  };

  useEffect(() => {
    const loadAutoAssignmentSetting = async () => {
      try {
        const type_auto =
          user.role.id === ROLES.directorApd.id
            ? "apd_auto_assignment"
            : "dbf_auto_assignment";
        const data = await fetchAutoAssignmentSetting(type_auto);
        const newValue = data.value === "true";
        setIsAutoAssignmentEnabled(newValue);
        localStorage.setItem("autoAssignmentSetting", newValue.toString());
      } catch (error) {
        console.error("Error fetching auto assignment setting:", error);
      }
    };

    loadAutoAssignmentSetting();
  }, []);

  const toggleAutoAssignmentHandler = () => {
    const toggleAutoAssignmentSetting = async () => {
      try {
        const dbfName = user.role.id === ROLES.directorApd.id ? "apd" : "dbf";
        await toggleAutoAssignment(dbfName);
        const newValue = !isAutoAssignmentEnabled;
        setIsAutoAssignmentEnabled(newValue);
        localStorage.setItem("autoAssignmentSetting", newValue.toString());
        if (newValue === true) {
          toast.success("Автораспределение запущено");
        } else {
          toast.success("Автораспределение остановлено");
        }
      } catch (error) {
        console.error("Error toggling auto assignment:", error);
      }
    };

    toggleAutoAssignmentSetting();
  };

  return (
    <>
      <Stack sx={{ gap: 2.5, py: 5, px: 2.5, maxWidth: "1130px" }}>
        <Box>
          <BoxTitle title={"Заявления"}>
            {user.role.id === ROLES.directorDbf.id && (
              <Button onClick={toggleAutoAssignmentHandler}>
                {isAutoAssignmentEnabled
                  ? "Остановить автораспределение"
                  : "Запустить автораспределение"}
              </Button>
            )}

            {user.role.id === ROLES.directorApd.id ? (
              <>
                {applicationAcceptanceIsSuccess && (
                  <>
                    <Button onClick={toggleAutoAssignmentHandler}>
                      {isAutoAssignmentEnabled
                        ? "Остановить автораспределение"
                        : "Запустить автораспределение"}
                    </Button>

                    <Button
                      onClick={
                        isAcceptingApplications ||
                        applicationAcceptance.application_apply
                          ? handleCancelAcceptingApplications
                          : handleOpenModalAcceptingApplications
                      }
                    >
                      {isAcceptingApplications ||
                      applicationAcceptance.application_apply
                        ? "Остановить прием заявлений"
                        : "Запустить прием заявлений"}
                    </Button>
                  </>
                )}
              </>
            ) : null}
          </BoxTitle>

          <Modal
            open={isConfirmationOpen}
            onClose={() => setIsConfirmationOpen(false)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                height: 250,
              }}
            >
              <TextField
                id="fromDate"
                label="Дата от"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: "20px" }}
              />

              <TextField
                id="toDate"
                label="Дата до"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: "20px" }}
              />

              <Select
                value={selectedTypeAcceptingApplication}
                onChange={(e) =>
                  setSelectedTypeAcceptingApplication(+e.target.value)
                }
                sx={{
                  width: 200,
                  mr: 2,
                }}
              >
                <MenuItem value={1}>Основной</MenuItem>
                <MenuItem value={2}>Дополнительный</MenuItem>
              </Select>

              <Button onClick={handleStartAcceptingApplications} sx={{ mr: 2 }}>
                Подтвердить
              </Button>
            </Box>
          </Modal>
        </Box>

        <Debounced
          value={search}
          onChange={(search) => setQuery({ search, page: 1 })}
        >
          {(value, onChange) => (
            <TextField
              type="search"
              placeholder="Поиск по наименованию, ИИН/БИН и тд."
              value={value}
              variant="outlined"
              sx={{ maxWidth: 375 }}
              onChange={(event) => onChange(event.target.value)}
            />
          )}
        </Debounced>

        {tab === "active" && (
          <>
            <Button
              sx={{ alignSelf: "flex-start" }}
              variant="outlined"
              onClick={() => setExpanded(!expanded)}
              startIcon={expanded ? <CloseOutlined /> : <FilterAltOutlined />}
            >
              Фильтр
            </Button>

            <Collapse in={expanded}>
              <DeclarationsFilters disabled={applicationsIsLoading} />
            </Collapse>
          </>
        )}

        {tab === "archive" && (
          <Autocomplete
            value={year}
            onChange={(_, year) => setYear(year)}
            options={Array.from(
              { length: 6 },
              (_, i) => new Date().getFullYear() - i,
            )}
            getOptionLabel={(option: number) => option.toString()}
            renderInput={(params) => (
              <TextField {...params} label="Год" sx={{ width: 150 }} />
            )}
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
        )}

        <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
          <Tab
            label="Активные"
            sx={{ textTransform: "capitalize" }}
            value="active"
          />

          <Tab
            label="Архив"
            sx={{ textTransform: "capitalize" }}
            value="archive"
          />
        </Tabs>

        {((applicationsIsLoading && tab === "active") ||
          (archiveApplicationsIsLoading && tab === "archive")) && (
          <Box sx={{ display: "grid", placeItems: "center", p: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {tab === "active" && applicationsIsSuccess && (
          <TableDirectorDeclarations
            rows={applications.data}
            rowCount={applications.meta.total}
            page={page}
            perPage={per_page}
            onChangePage={(page) => setQuery({ page })}
            onChangePerPage={(per_page) => setQuery({ per_page, page: 1 })}
          />
        )}

        {tab === "archive" && archiveApplicationsIsSuccess && (
          <TableDirectorDeclarationsArchieve
            rows={archiveApplications.data}
            rowCount={archiveApplications.meta.total}
            page={page}
            perPage={per_page}
            onChangePage={(page) => setQuery({ page })}
            onChangePerPage={(per_page) => setQuery({ per_page, page: 1 })}
          />
        )}
      </Stack>

      <AutoConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        applicationIds={[]}
      />
    </>
  );
};

export default Declaration;
